import g from "react";
const m = {
  chars: "-./^*!}<~$012345abcdef",
  interval: 50
}, b = (t) => t[Math.floor(Math.random() * t.length)], v = (t, n, l, a, r, e) => n > t ? a[n] : t >= l && n < t - l ? r[n] : b(e), V = (t) => {
  const { chars: n, interval: l, callback: a, initialValue: r } = {
    ...m,
    ...t
  };
  let e, s;
  r && (e = r, a(e));
  function* d(o, c = "") {
    const u = o.length, i = c.length, f = Math.max(u, i), p = 2 * f;
    let h = 0;
    for (yield c; h < p; )
      yield [...new Array(f)].map((x, y) => v(h, y, f, c, o, n)).join(""), h++;
    yield o;
  }
  return (o) => {
    clearInterval(s);
    const c = d(o, e);
    return new Promise((u) => {
      s = setInterval(() => {
        var i = c.next();
        i.done ? (clearInterval(s), u(e)) : (e = i.value, a(e));
      }, l);
    });
  };
};
function I(t, n) {
  let l = "", a = {};
  typeof t == "object" ? a = t : typeof t == "string" && (l = t, a = n || {});
  const [r, e] = g.useState(), [s, d] = g.useState(
    () => V({
      ...a,
      initialValue: l,
      callback: e
    })
  );
  return [r, s];
}
export {
  V as dencrypt,
  I as useDencrypt
};
